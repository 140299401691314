<template>
    <div class="result_wpr new">
        <div class="actions mt-4">
            <ul id="transation-filter-bar" class="left">
                <li class="optionDrops sort_list" @click="handleToggleDropdown('ftlist')" v-click-outside="closeFtlist">
                    {{ ftlistDropdown }} <i class="fas fa-angle-down"></i>
                    <div class="dropdown_wpr" :class="ftlist ? 'active' : ''">
                        <ul>
                            <li @click="handleOrderByFilter('date');">Date</li>
                            <li @click="handleOrderByFilter('amount');">Amount</li>
                        </ul>
                    </div>
                </li>
                <li class="optionDrops contacts-tabs" @click="handleToggleDropdown('sortlist')" v-click-outside="closeSortlist">
                    {{ sortlistDropdown }}<i class="fas fa-angle-down"></i>
                    <div class="dropdown_wpr" :class="sortlist ? 'active' : ''">
                        <ul>
                            <li @click="handleSortByFilter('newest');">Newest First</li>
                            <li @click="handleSortByFilter('oldest');">Oldest First</li>
                        </ul>
                    </div>
                </li>
                <li class="optionDrops contacts-tabs" @click="handleToggleDropdown('translist')" v-click-outside="closeTranslist">
                    {{ transactionFilter }}<i class="fas fa-angle-down"></i>
                    <div class="dropdown_wpr" :class="translist ? 'active' : ''">
                        <ul>
                            <li @click="handleTransactionFilter('processor')">Payment Processor</li>
                            <li @click="handleTransactionFilter('transaction')">Transaction Type</li>
                            <li @click="handleTransactionFilter('tracking')">Tracking ID</li>
                            <li @click="handleTransactionFilter('amount')">Order Amount</li>
                        </ul>
                    </div>
                </li>
                <li class="optionDrops contacts-tabs" @click="handleToggleDropdown('selectrans')" v-if="transactionFilter == 'Payment Processor'" v-click-outside="closeSelectrans">
                    {{ processorName }}<i class="fas fa-angle-down"></i>
                    <div class="dropdown_wpr" :class="selectrans ? 'active' : ''">
                        <ul>
                            <li @click="handleProcessorFilter({ name: 'Select Processor', id: 0 })">Select Processor</li>
                            <li v-for="(processor, p) in processors" :key="p" @click="handleProcessorFilter(processor)">{{ processor.name }}</li>
                        </ul>
                    </div>
                </li>
                <li class="optionDrops contacts-tabs" @click="handleToggleDropdown('selectrans')" v-if="transactionFilter == 'Transaction Type'" v-click-outside="closeSelectrans">
                    {{ transactionType }}<i class="fas fa-angle-down"></i>
                    <div class="dropdown_wpr" :class="selectrans ? 'active' : ''">
                        <ul>
                            <li @click="handleTransactionTypeFilter()">Select Transaction Type</li>
                            <li @click="handleTransactionTypeFilter('all')">All</li>
                            <li @click="handleTransactionTypeFilter('purchase')">Purchase</li>
                            <li @click="handleTransactionTypeFilter('refund')">Refund</li>
                        </ul>
                    </div>
                </li>
                <li class="optionDrops contacts-tabs" @click="handleToggleDropdown('selectrans')" v-if="transactionFilter == 'Tracking ID'" v-click-outside="closeSelectrans">
                    {{ trackingId }}<i class="fas fa-angle-down"></i>
                    <div class="dropdown_wpr" :class="selectrans ? 'active' : ''">
                        <ul>
                            <li @click="handleTrackingIdFilter()">Select Tracking ID</li>
                            <li v-for="(trackingId, t) in trackingIds" :key="t" @click="handleTrackingIdFilter(trackingId)">{{ trackingId }}</li>
                        </ul>
                    </div>
                </li>
                <li class="optionDrops contacts-tabs" @click="handleToggleDropdown('selectrans')" v-if="transactionFilter == 'Order Amount'" v-click-outside="closeSelectrans">
                    {{ OrderAmountCondition }}<i class="fas fa-angle-down"></i>
                    <div class="dropdown_wpr" :class="selectrans ? 'active' : ''">
                        <ul>
                            <li @click="handleOrderAmountFilter()">Select Type</li>
                            <li @click="handleOrderAmountFilter('less')">Less Than</li>
                            <li @click="handleOrderAmountFilter('greater')">Greater Than</li>
                        </ul>
                    </div>
                </li>
                <!-- <li class="breaker"></li> -->
                <li class="order_amt" v-if="transactionFilter == 'Order Amount'">
                    <input type="number" placeholder="0" @input="isAmountTyping = true" v-model.trim="params.amount" />
                </li>
                <li class="search_area" @click="closeAllDropdowns()">
                    <input type="text" placeholder="Search..." @input="isTyping = true" :disabled="orderTransactionsLoader" v-model.trim="params.search_transaction"/>
                    <button class="search_btn">
                        <i class="fas fa-search"></i>
                    </button>
                </li>
                <li class="filter_btn" @click="mobile_filter = true;">
                    <span></span>
                    <span></span>
                    <span></span>
                </li>
            </ul>
        </div>
        <div class="contact_loader" v-if="orderTransactionsLoader"><quote-loader /></div>
        <table class="show_header" v-show="!orderTransactionsLoader">
            <thead>
                <tr>
                    <th></th>
                    <th align="left">Product</th>
                    <th>Actions</th>
                    <th align="right">Amount</th>
                </tr>
            </thead>
            <tbody v-if="orderTransactions.total">
                <tr v-for="(orderTransaction, o) in orderTransactions.data" :key="o">
                    <td>
                        <div class="user_wpr" @click="handleOrderDetails(orderTransaction.client)">
                            <div class="user_img m-0">
                                <img :src="orderTransaction.client &&  orderTransaction.client.profile_pic ? orderTransaction.client.profile_pic : require('@/assets/images/male-female.png')" alt="">
                            </div>
                        </div>
                    </td>
                    <td align="left">
                        <h4>{{ orderTransaction.product && orderTransaction.product.name ? orderTransaction.product.name : '-' }}
                            <span>{{ orderTransaction.has_bump ? `Bump payment of ${ companyCurrencySymbol+parseFloat(orderTransaction.bump_price).toFixed(2)}` : `One time payment of ${companyCurrencySymbol+parseFloat(orderTransaction.price).toFixed(2)} ${ orderTransaction.is_paid_trial ? ' (Trial)' : ''}` }}</span>
                        </h4>
                        <h6>{{ orderTransaction.client &&  orderTransaction.client.full_name ? orderTransaction.client.full_name : '-' }}</h6>
                        <h6>{{ orderTransaction.client &&  orderTransaction.client.email ? orderTransaction.client.email : '-' }}</h6>
                    </td>
                    <td>
                        <ul class="action_list">
                            <li v-if="compnayUserCan('update', 'checkouts')" title="Refund" @click="handleRefund(orderTransaction)"><i class="fas fa-reply"></i></li>
                            <li title="Send Invoice" @click="sendOrderInvoice(orderTransaction.order_id)"><i :class="{ 'fas fa-paper-plane': sendInvoiceLoader == 0 || sendInvoiceLoader != orderTransaction.order_id, 'fa fa-spin fa-spinner': sendInvoiceLoader == orderTransaction.order_id }"></i></li>
                            <li title="More" @click="handleOrderDetails(orderTransaction.client)"><i class="fas fa-user"></i></li>
                            <li title="Download Invoice" @click="downloadOrderInvoice(orderTransaction.order_id)"><i :class="{ 'fas fa-download': downloadInvoiceLoader == 0 || downloadInvoiceLoader != orderTransaction.order_id, 'fa fa-spin fa-spinner': downloadInvoiceLoader == orderTransaction.order_id }"></i></li>
                        </ul>
                    </td>
                    <td align="right">
                        <h4>{{companyCurrencySymbol}}{{ orderTransaction.has_bump ? parseFloat(orderTransaction.bump_price).toFixed(2) : parseFloat(orderTransaction.price).toFixed(2) }}</h4>
                        <h6>{{ moment(orderTransaction.created_at).format('ll') }}</h6>
                        <h6>{{ moment(orderTransaction.created_at).format('LT') }}</h6>
                    </td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr>
                    <td colspan="4" class="px-4 text-center" style="border-radius: 0 0 10px 10px;">No Records Found</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="header_filter" :class="{'show' : mobile_filter}">
        <button class="close_btn" @click="mobile_filter = false;"><i class="fas fa-times"></i></button>
        <div class="action_area">
            <h3 class="sub_heading2 mb-4">Filters</h3>
            <ul>
                <li class="optionDrops sort_list" @click="handleToggleDropdown('ftlist2')" v-click-outside="closeFtlist2">
                    {{ ftlistDropdown }} <i class="fas fa-angle-down"></i>
                    <div class="dropdown_wpr" :class="ftlist2 ? 'active' : ''">
                        <ul>
                            <li @click="handleOrderByFilter('date');">Date</li>
                            <li @click="handleOrderByFilter('amount');">Amount</li>
                        </ul>
                    </div>
                </li>
                <li class="optionDrops contacts-tabs" @click="handleToggleDropdown('sortlist2')" v-click-outside="closeSortlist2">
                    {{ sortlistDropdown }}<i class="fas fa-angle-down"></i>
                    <div class="dropdown_wpr" :class="sortlist2 ? 'active' : ''">
                        <ul>
                            <li @click="handleSortByFilter('newest');">Newest First</li>
                            <li @click="handleSortByFilter('oldest');">Oldest First</li>
                        </ul>
                    </div>
                </li>
                <li class="optionDrops contacts-tabs" @click="handleToggleDropdown('translist2')" v-click-outside="closeTranslist2">
                    {{ transactionFilter }}<i class="fas fa-angle-down"></i>
                    <div class="dropdown_wpr" :class="translist2 ? 'active' : ''">
                        <ul>
                            <li @click="handleTransactionFilter('processor')">Payment Processor</li>
                            <li @click="handleTransactionFilter('transaction')">Transaction Type</li>
                            <li @click="handleTransactionFilter('tracking')">Tracking ID</li>
                            <li @click="handleTransactionFilter('amount')">Order Amount</li>
                        </ul>
                    </div>
                </li>
                <li class="optionDrops contacts-tabs" @click="handleToggleDropdown('selectrans2')" v-if="transactionFilter == 'Payment Processor'" v-click-outside="closeSelectrans2">
                    {{ processorName }}<i class="fas fa-angle-down"></i>
                    <div class="dropdown_wpr" :class="selectrans2 ? 'active' : ''">
                        <ul>
                            <li @click="handleProcessorFilter({ name: 'Select Processor', id: 0 })">Select Processor</li>
                            <li v-for="(processor, p) in processors" :key="p" @click="handleProcessorFilter(processor)">{{ processor.name }}</li>
                        </ul>
                    </div>
                </li>
                <li class="optionDrops contacts-tabs" @click="handleToggleDropdown('selectrans2')" v-if="transactionFilter == 'Transaction Type'" v-click-outside="closeSelectrans2">
                    {{ transactionType }}<i class="fas fa-angle-down"></i>
                    <div class="dropdown_wpr" :class="selectrans2 ? 'active' : ''">
                        <ul>
                            <li @click="handleTransactionTypeFilter()">Select Transaction Type</li>
                            <li @click="handleTransactionTypeFilter('all')">All</li>
                            <li @click="handleTransactionTypeFilter('purchase')">Purchase</li>
                            <li @click="handleTransactionTypeFilter('refund')">Refund</li>
                        </ul>
                    </div>
                </li>
                <li class="optionDrops contacts-tabs" @click="handleToggleDropdown('selectrans2')" v-if="transactionFilter == 'Tracking ID'" v-click-outside="closeSelectrans">
                    {{ trackingId }}<i class="fas fa-angle-down"></i>
                    <div class="dropdown_wpr" :class="selectrans2 ? 'active' : ''">
                        <ul>
                            <li @click="handleTrackingIdFilter()">Select Tracking ID</li>
                            <li v-for="(trackingId, t) in trackingIds" :key="t" @click="handleTrackingIdFilter(trackingId)">{{ trackingId }}</li>
                        </ul>
                    </div>
                </li>
                <li class="optionDrops contacts-tabs" @click="handleToggleDropdown('selectrans2')" v-if="transactionFilter == 'Order Amount'" v-click-outside="closeSelectrans2">
                    {{ OrderAmountCondition }}<i class="fas fa-angle-down"></i>
                    <div class="dropdown_wpr" :class="selectrans2 ? 'active' : ''">
                        <ul>
                            <li @click="handleOrderAmountFilter()">Select Type</li>
                            <li @click="handleOrderAmountFilter('less')">Less Than</li>
                            <li @click="handleOrderAmountFilter('greater')">Greater Than</li>
                        </ul>
                    </div>
                </li>
                <li class="order_amt" v-if="transactionFilter == 'Order Amount'">
                    <input type="number" placeholder="0" @input="isAmountTyping = true" v-model.trim="params.amount" />
                </li>
            </ul>
        </div>
    </div>
    <div class="table_footer">
        <ul>
            <li>{{ orderTransactions.from ? orderTransactions.from : 0 }} - {{ orderTransactions.to ? orderTransactions.to : 0 }} of {{ orderTransactions.total }}</li>
        </ul>
    </div>
    <div class="pagination" v-show="orderTransactions.total">
        <pagination v-model="params.page" :pages="orderTransactions.last_page" @update:modelValue="handlePagination" />
    </div>

    <refund-component v-model="refundPayment" :refund-amount="refundAmount" :order-id="refundOrderId" :refresh-records="handleRefreshRecords" />
    <profile-component v-model="details" :selected-client="selectedClient" />
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions, mapGetters } from 'vuex'

    const RefundComponent = defineAsyncComponent(() => import('@/pages/checkout/components/Refund'))
    const ProfileComponent = defineAsyncComponent(() => import('@/pages/checkout/components/client/Profile'))

    import moment from 'moment'
    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'

    export default {
        name: 'Order Transaction',

        data () {
            return {
                refundPayment: false,
                ftlist: false,
                ftlist2: false,
                sortlist: false,
                sortlist2: false,
                translist: false,
                translist2: false,
                transactionFilter: 'Transaction Type',
                selectrans: false,
                selectrans2: false,
                details: false,
                ftlistDropdown: 'Date',
                sortlistDropdown: 'Newest First',
                params: {
                    page: 1,
                    per_page: 10,
                    order_by: 'id',
                    sort_by: 'desc',
                    search_transaction: '',
                    transaction_filter: 'transaction',
                    processor_id: 0,
                    transaction_type: 'all',
                    tracking_id: 0,
                    amount: 0,
                    amount_filter: 'all'
                },
                isTyping: false,
                isAmountTyping: false,
                selectedClient: {},
                moment,
                processorName: 'Select Processor',
                transactionType: 'Select Transaction Type',
                trackingId: 'Select Tracking ID',
                OrderAmountCondition: 'Select Type',
                isActiveDropdown: false,
                refundAmount: 0,
                refundOrderId: 0,
                searchField: false,
                mobile_filter: false,
            }
        },

        props: {
            orderParams: {
                type: Object,
                default: () => {}
            },
            transactionWatcher: {
                type: Number,
                default: 0,
            },
        },

        components:{
            RefundComponent,
            ProfileComponent,
        },

        watch: {
            transactionWatcher () {
                const vm  = this;

                vm.params.page = 1;

                vm.params =  { ...vm.params, ...vm.orderParams };

                vm.getOrderTransactions(vm.params)
            },

            'params.search_transaction' (val) {
                const vm = this;

                vm.params.page = 1;

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.params.search_transaction != null && (vm.params.search_transaction.length >= 2 || vm.params.search_transaction.length == 0)) {
                        vm.getOrderTransactions(vm.params);
                    }
                }
            },

            'params.amount' (val) {
                const vm = this;

                vm.params.page = 1;

                setTimeout(() => {
                    vm.isAmountTyping = false;
                }, 1500);
            },

            isAmountTyping (val) {
                const vm = this;

                if (!val) {
                    vm.getOrderTransactions(vm.params);
                }
            },
        },

        computed: {
            ...mapState({
                orderTransactions: state => state.checkoutModule.orderTransactions,
                orderTransactionsLoader: state => state.checkoutModule.orderTransactionsLoader,
                processors: state => state.checkoutModule.processors,
                trackingIds: state => state.checkoutModule.trackingIds,
                sendInvoiceLoader: state => state.checkoutModule.sendInvoiceLoader,
                downloadInvoiceLoader: state => state.checkoutModule.downloadInvoiceLoader,
                companyCurrencySymbol: state => state.checkoutModule.companyCurrencySymbol,
            }),

            ...mapGetters({
                compnayUserCan: 'authModule/COMPANY_USER_CAN'
            }),
        },

        mounted () {
            const vm  = this;

            vm.params = {...vm.params, ...vm.orderParams };
            vm.getOrderTransactions(vm.params);

            if (!vm.trackingIds.length) {
                vm.getTrackingIds();
            }

            document.addEventListener('click', function(e) {
                const el = document.getElementById('transation-filter-bar');

                if (el && !el.contains(e.target) && vm.isActiveDropdown) {
                    vm.closeAllDropdowns();
                }
            });
        },

        methods:{
            ...mapActions({
                getOrderTransactions: 'checkoutModule/getOrderTransactions',
                getTrackingIds: 'checkoutModule/getTrackingIds',
                sendOrderInvoice: 'checkoutModule/sendOrderInvoice',
                downloadOrderInvoice: 'checkoutModule/downloadOrderInvoice',
            }),

            handleTransactionFilter (type) {
                const vm = this;

                if (vm.params.transaction_filter != type) {
                    vm.params.transaction_filter = type;

                    if (type == 'processor') {
                        vm.transactionFilter = 'Payment Processor';
                    } else if (type == 'transaction') {
                        vm.transactionFilter = 'Transaction Type';
                    } else if (type == 'tracking') {
                        vm.transactionFilter = 'Tracking ID';
                    } else if (type == 'amount') {
                        vm.transactionFilter = 'Order Amount';
                    }

                    vm.resetParams();
                }
            },

            handleToggleDropdown (type) {
                const vm       = this;
                const isActive = vm[type];

                vm.closeAllDropdowns();

                if (!isActive) {
                    vm[type]            = true;
                    vm.isActiveDropdown = true;
                }
            },

            handlePagination (page) {
                const vm = this;

                vm.params.page = page;
                vm.getOrderTransactions(vm.params);
            },

            handleOrderDetails (client) {
                const vm = this;

                vm.selectedClient = client;
                vm.details        = true;
            },

            resetParams () {
                const vm = this;

                vm.processorName             = 'Select Processor';
                vm.transactionType           = 'Select Transaction Type';
                vm.trackingId                = 'Select Tracking ID';
                vm.OrderAmountCondition      = 'Select Type';

                vm.params.page               = 1;
                vm.params.search_transaction = '';
                vm.params.processor_id       = 0;
                vm.params.transaction_type   = 'all';
                vm.params.tracking_id        = 0;
                vm.params.amount             = 0;
                vm.params.amount_filter      = 'all';

                vm.getOrderTransactions(vm.params);
            },

            handleProcessorFilter (processor) {
                const vm = this;

                vm.processorName       = processor.name;
                vm.params.processor_id = processor.id;

                vm.getOrderTransactions(vm.params);
            },

            handleTransactionTypeFilter (type = 0) {
                const vm = this;

                if (type == 'all') {
                    vm.transactionType = 'All';
                } else if (type == 'purchase') {
                    vm.transactionType = 'Purchase';
                } else if (type == 'refund') {
                    vm.transactionType = 'Refund';
                } else {
                    vm.transactionType = 'Select Transaction Type';
                }

                vm.params.transaction_type = type;

                vm.getOrderTransactions(vm.params);
            },

            handleTrackingIdFilter (trackingId = 0) {
                const vm = this;

                vm.trackingId         = trackingId ? trackingId : 'Select Tracking ID';
                vm.params.tracking_id = trackingId;

                vm.getOrderTransactions(vm.params);
            },

            handleOrderAmountFilter (type = 'all') {
                const vm = this;

                if (type == 'all') {
                    vm.OrderAmountCondition = 'Select Type';
                } else if (type == 'less') {
                    vm.OrderAmountCondition = 'Less Than';
                } else if (type == 'greater') {
                    vm.OrderAmountCondition = 'Greater Than';
                }

                vm.params.amount_filter = type;

                vm.getOrderTransactions(vm.params);
            },

            closeAllDropdowns () {
                const vm = this;

                vm.ftlist           = false;
                vm.sortlist         = false;
                vm.translist        = false;
                vm.selectrans       = false;
                vm.isActiveDropdown = false;
            },

            handleRefund (order) {
                const vm = this;

                if (order.refund_amount || order.bump_refund_amount) {
                    Swal.fire(Helper.swalWarningOptions('Oops!', `This order has already been refunded, you can not refund it again.`, 'OK'));
                } else {
                    const refundAmount  = order.has_bump ? order.bump_price : order.price;

                    vm.refundAmount     = parseFloat(refundAmount).toFixed(2);
                    vm.refundOrderId    = order.order_id;
                    vm.refundPayment    = true;
                }
            },

            handleOrderByFilter (type) {
                const vm = this;

                if (type == 'date') {
                    vm.ftlistDropdown  = 'Date';
                    vm.params.order_by = 'id';
                } else if (type == 'amount') {
                    vm.ftlistDropdown = 'Amount';
                    vm.params.order_by = 'price';
                }

                vm.resetParams();
            },

            handleSortByFilter (type) {
                const vm = this;

                if (type == 'newest') {
                    vm.sortlistDropdown  = 'Newest First';
                    vm.params.sort_by = 'desc';
                } else if (type == 'oldest') {
                    vm.sortlistDropdown = 'Oldest First';
                    vm.params.sort_by = 'asc';
                }

                vm.resetParams();
            },

            handleRefreshRecords () {
                const vm = this;

                vm.getOrderTransactions(vm.params);
            },

            closeFtlist(){
                const vm = this;

                vm.ftlist = false;
            },
            closeFtlist2(){
                const vm = this;

                vm.ftlist2 = false;
            },

            closeSortlist(){
                const vm = this;

                vm.sortlist = false;
            },
            closeSortlist2(){
                const vm = this;

                vm.sortlist2 = false;
            },

            closeTranslist(){
                const vm = this;

                vm.translist = false;
            },
            closeTranslist2(){
                const vm = this;

                vm.translist2 = false;
            },

            closeSelectrans(){
                const vm = this;

                vm.selectrans = false;
            },
            closeSelectrans2(){
                const vm = this;

                vm.selectrans2 = false;
            },
        }
    }
</script>

<style scoped>
    .actions > ul li.duration{
        position: relative;
    }

    .actions > ul li.order_amt {
        flex: 0 1 80px;
        height: 24px;
        background: #fff;
        border-radius: 3px;
    }

    .actions > ul li.order_amt input[type=number] {
        width: 100%;
        padding: 0 5px 0 10px;
        /* -moz-appearance: textfield; */
    }

    /* .actions > ul li.order_amt input[type=number]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    } */
    .result_wpr.new{
        overflow: visible;
        min-height: 355px;
    }

    .result_wpr table td:first-child, .result_wpr table th:first-child  {
        width: 50px;
        padding: 20px;
        text-align: left;
    }

    .result_wpr table td h4 {
        font-size: 15px;
        line-height: 20px;
        color: #121525;
        font-weight: 500;
        margin-bottom: 5px;
    }

    .result_wpr table td h4 span {
        font-size: 11px;
        line-height: 14px;
        color: #121525;
        font-weight: 500;
        background: #e9e9e9;
        padding: 3px 8px;
        border-radius: 10px;
        display: inline-block;
    }

    .result_wpr table td h6 {
        font-size: 11px;
        line-height: 15px;
        color: #121525;
        font-weight: 400;
    }

    .result_wpr table td .action_list {
        display: flex;
        justify-content: center;
        gap: 15px;
    }

    .result_wpr table td .action_list li i {
        color: #2f7eed;
        border-radius: 50%;
        font-size: 15px;
        cursor: pointer;
    }

    .actions > ul li.optionDrops.sort_list .dropdown_wpr {
        width: auto;
    }
    .result_wpr.new .actions > ul.left{
        width: 100%;
    }
    .result_wpr.new .actions > ul li.search_area {
        padding: 0 31px 0 0;
        border-radius: 17px;
        background: #fff;
        position: relative;
        margin-left: auto;
    }
    .result_wpr.new .actions > ul li.breaker{
        padding: 0;
    }
    .header_filter{
        position: fixed;
        top: 0;
        bottom: 0;
        background: #fff;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
        width: 250px;
        overflow-y: auto;
        right: -250px;
        z-index: 12;
        transition: all 0.3s ease-in-out;
        text-align: left;
    }
    .header_filter.show{
        right: 0;
    }
    .header_filter .close_btn{
        position: absolute;
        right: 20px;
        top: 20px;
        width: 20px;
        height: 20px;
        font-size: 10px;
        border-radius: 50%;
        background: #eaeaea;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #5a5a5a;
        cursor: pointer;
    }
    .header_filter .top_area{
        padding: 20px;
    }
    .header_filter .top_area h3{
        font-size: 17px;
        line-height: 22px;
        font-weight: 500;
        color: #121525;
        margin-bottom: 15px;
    }
    .header_filter .search_area {
        padding: 0 31px 0 0;
        border-radius: 17px;
        background: #f5f5f5;
        position: relative;
        margin: 15px 0;
    }
    .header_filter .search_area input[type=text] {
        font-size: 11px;
        height: 30px;
        width: 100%;
        background: transparent;
        padding: 0 0 0 15px;
        transition: all 0.3s ease-in-out;
    }
    .header_filter .search_area .search_btn {
        position: absolute;
        right: 0;
        top: 0;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        font-size: 11px;
        color: #121525;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .header_filter .top_area ul {
        list-style-type: none;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-top: 5px;
    }
    .header_filter .top_area ul li {
        height: 30px;
        width: 30px;
        background: #2f7eed;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.02);
        margin-right: 6px;
    }
    .header_filter .top_area ul li img {
        max-width: 16px;
        height: auto;
        filter: brightness(100);
    }
    .header_filter .action_area{
        padding: 20px;
        border-top: 1px solid #e9e9e9;
    }
    .header_filter .action_area ul li .checkbox h5 {
        font-size: 13px;
        line-height: 16px;
        font-weight: 400;
        color: #121525;
        margin: 5px 0;
    }
    .header_filter .action_area > ul > li {
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
        color: #5a5a5a;
        text-align: left;
        /* background: #f5f5f5; */
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        margin-bottom: 10px;
    }
    .header_filter .action_area > ul > li.optionDrops, .header_filter .action_area > ul > li.order_amt{
        padding: 7px 15px;
        border: 1px solid #e9e9e9;
        border-radius: 20px;
    }
    .header_filter .action_area > ul > li > i{
        margin-left: auto;
    }
    .header_filter .action_area .dropdown_wpr ul{
        max-height: 250px;
        overflow-y: auto;
    }
    .header_filter .action_area .dropdown_wpr ul li{
        border: 0;
    }
    .header_filter .action_area .dropdown_wpr ul li.active{
        background: #f5f5f5;
    }
    .result_wpr.new .actions > ul li.filter_btn{
        background: #fff;
        width: 30px;
        height: 30px;
        border-radius: 3px;
        padding: 4px 6px;
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        cursor: pointer;
    }
    .filter_btn span{
        height: 2px;
        width: 100%;
        border-radius: 2px;
        background: #2f7eed;
        margin: 2px 0;
    }
    .filter_btn span:nth-child(2){
        width: 60%;
    }
    @media(max-width: 1199px){
        .result_wpr table{
            margin-top: 20px;
        }
    }
    @media(max-width: 991px){
        .acton_header .search_area input{
            width: 200px;
            height: 30px;
        }
        .acton_header .search_area .search_btn{
            height: 30px;
        }
        .acton_header .left_section .search_area{
            display: none;
        }
        .acton_header .right_section > ul > li:not(.add_btn), .acton_header .right_section .switch_option{
            display: none;
        }
        .result_wpr.new .actions > ul > li:not(.search_area, .filter_btn){
            display: none;
        }
        .result_wpr.new .actions > ul li.filter_btn{
            display: flex;
        }
        .result_wpr.new .actions > ul li.search_area{
            margin: 0 auto 0 0;
        }
    }
    @media(max-width: 767px){
        .result_wpr.new .actions > ul li.search_area input{
            width: 200px;
            height: 30px;
        }
        .result_wpr.new .actions > ul li.search_area .search_btn{
            height: 30px;
            width: 30px;
        }
    }
    @media(min-width: 992px){
        .header_filter{
            display: none;
        }
    }
    /* @media(max-width: 1199px){
        .result_wpr.new .actions{
            margin-bottom: 15px;
        }
    }
    @media(max-width: 600px){
        .result_wpr.new .actions > ul li.search_area input{
            width: 170px;
        }
    } */
    @media(max-width: 450px){
        .result_wpr.new .actions > ul li.search_area{
            width: 250px;
        }
        .result_wpr.new .actions > ul li.search_area input{
            padding-left: 15px;
        }
    }
</style>
